import React, { useState, useEffect } from "react";
import "./Gallery.scss";
import axios from "axios";
import Banner from "../../../components/Banner/Banner";
import safari from "../../../assets/safari.jpg";

const Gallery = () => {
  const [activeTab, setActiveTab] = useState("albums");
  const [isFullImgOpen, setIsFullImgOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(false); // New state for image loading
  const [albums, setAlbums] = useState([]);
  const API_URL = "https://backend.himalayafairreisen.com";

  useEffect(() => {
    // Simulate loading effect
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3500);

    fetchAlbums();

    return () => clearTimeout(timer);
  }, []);

  // Fetch albums from the backend
  const fetchAlbums = async () => {
    try {
      const response = await axios.get(`${API_URL}/albums`);
      const fetchedAlbums = response.data;

      // Fetch images for each album if not already included
      const albumsWithImages = await Promise.all(
        fetchedAlbums.map(async (album) => {
          const photosResponse = await fetchPhotos(album.id);
          return { ...album, images: photosResponse };
        })
      );

      setAlbums(albumsWithImages);
      console.log("Fetched albums with images:", albumsWithImages);
    } catch (error) {
      console.error("Error fetching albums:", error.message);
      alert("Failed to fetch albums. Please try again.");
    }
  };

  // Fetch photos for a specific album
  const fetchPhotos = async (albumId) => {
    try {
      const response = await axios.get(`${API_URL}/photos`, {
        params: { album_id: albumId },
      });
      // Ensure the data contains only images related to the specific album
      return response.data.filter((photo) => photo.album_id === albumId);
    } catch (error) {
      console.error("Error fetching photos:", error.message);
      return [];
    }
  };

  const openFullImg = (pic) => {
    setCurrentImg(pic);
    setIsFullImgOpen(true);
  };

  const closeFullImg = () => {
    setIsFullImgOpen(false);
    setCurrentImg("");
  };

  const handleBackToAlbums = () => {
    window.scrollTo(0, 0);
    setActiveTab("albums");
  };

  const handleOpenAlbum = async (index) => {
    setIsImageLoading(true); // Start image loading
    setActiveTab(`album-${index}`);
    setIsImageLoading(false); // Stop image loading after the album is opened
  };

  const renderAlbums = () => {
    return (
      <div className="album-gallery">
        {albums.map((album, index) => (
          <div key={index} className="album">
            <img
              src={`${API_URL}/albums/${album.id}/cover`} // Ensure this matches the backend route
              alt={album.title || "Album Cover"}
              className="album-cover"
              onClick={() => handleOpenAlbum(index)}
            />
            <div className="album-info">
              <h3>{album.name || "Untitled Album"}</h3>
              <p>{album.images?.length || 0} images</p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderImages = (albumIndex) => {
    const album = albums[albumIndex];
    return (
      <div>
        {isImageLoading ? ( // Display loader if images are loading
          <div className="cssloader">
            <div className="triangle1"></div>
            <div className="triangle2"></div>
            <p className="text">Please Wait</p>
          </div>
        ) : (
          <div className="image-gallery">
            {album.images?.map((photo, index) => (
              <img
                key={index}
                src={`${API_URL}/photos/${photo.id}/image`}
                onClick={() =>
                  openFullImg(`${API_URL}/photos/${photo.id}/image`)
                }
                alt={photo.alt || "Image"} // Use photo.alt instead of image.alt
                className="gallery-image"
              />
            ))}
          </div>
        )}
        <button className="back-albm" onClick={handleBackToAlbums}>
          Back to Albums
        </button>
      </div>
    );
  };

  const renderContent = () => {
    if (activeTab === "albums") {
      return renderAlbums();
    } else if (activeTab.startsWith("album-")) {
      const albumIndex = parseInt(activeTab.split("-")[1], 10);
      return renderImages(albumIndex);
    }
  };

  return (
    <div className="gallery-section">
      <Banner img={safari} title="Gallery" />

      <div className="tabs">
        <button
          className={`tab ${activeTab === "albums" ? "active" : ""}`}
          onClick={handleBackToAlbums}
          style={{ opacity: "0", visibility: "hidden" }}
        >
          Images
        </button>
      </div>
      <div className="content">
        {isLoading ? (
          <div className="cssloader">
            <div className="triangle1"></div>
            <div className="triangle2"></div>
            <p className="text">Please Wait</p>
          </div>
        ) : (
          renderContent()
        )}
      </div>

      {isFullImgOpen && (
        <div className="full-img" id="fullImgBox">
          <img src={currentImg} id="fullImg" alt="Selected" />
          <span onClick={closeFullImg} className="close-btn">
            X
          </span>
        </div>
      )}
    </div>
  );
};

export default Gallery;
