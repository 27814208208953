import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/himalayanfern1.png";

import footertop from "../../assets/footeralign.png"; // Import the new image

import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className="footer-top-image-wrapper">
        <img
          src={footertop}
          alt="Footer Top Image"
          className="footer-top-image"
        />
      </div>
      <div className="footer-container">
        <div className="footer-column">
          <img src={logo} alt="DEKAT ENGINEERING" className="footer-logo" />
          {/* <p className="tagline">Himalayan Friends</p> */}
        </div>

        <div className="footer-item">
          <div className="footer-column quick-links">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/activities">Activites</Link>
              </li>
              <li>
                <Link to="/destination">Destination</Link>
              </li>
              <li>
                <Link to="/about">Booking</Link>
              </li>
              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="footer-copyright">
          <hr />
          <p style={{ marginBottom: "-20px" }}>
            Copyright @ 2024 - Himalayan Fair Reisen
          </p>
          <p> Sano Bharyang, Kathmandu, Ph: +977 9707493626</p>
          <span>Powered By : ANRIKA</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
