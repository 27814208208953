import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import axios from "axios";
import "./Activites.scss";
import BannerImg from "../../assets/banner3.jpeg"; // Make sure the path and extension are correct

function Activites() {
  const [activities, setActivities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchActivities();
  }, []);

  const fetchActivities = async () => {
    try {
      const response = await axios.get(
        "https://backend.himalayafairreisen.com/activities"
      );
      setActivities(response.data);
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  };

  const handleCardClick = (title) => {
    navigate("/packages", { state: { activity: title } });
  };

  return (
    <div>
      <Banner img={BannerImg} title="Activites" />
      <header className="activities-header">
        <h2>Adventure and Exploring</h2>
        <p>
          Discover a variety of exciting activities to spark your adventurous
          spirit and explore the world around you.
        </p>
      </header>
      <div className="activities-grid">
        {activities.map((activity) => (
          <div
            key={activity.id}
            className="activity-card"
            onClick={() => handleCardClick(activity.title)}
          >
            <img src={activity.image_url} alt={activity.title} />
            <h3>{activity.title}</h3>
            <p>{activity.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Activites;
